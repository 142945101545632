<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <search-form v-bind="$attrs" />
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import date from 'utils/date-time'
import searchForm from './search-form'
import { latLngMapper } from '../ondemand/form/mappers'
import { mapGetters } from 'vuex'
import flightModel from 'pages/ondemand/flights/model'
const { addToDate, adjustDate } = date

export default {
  name: 'SmartSearch',
  components: {
    searchForm
  },
  data () {
    return {
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: date.toCivilDateTime(date.addToDate(date.newDate(), { minutes: 5 })),
          inbound: ''
        },
        options: {
          adults: 1,
          children: 0,
          rooms: 1
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup'
    })
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = {
            label: val.label,
            place_id: `${val.latitude},${val.longitude}`,
            value: `${val.latitude},${val.longitude}`
          }
        }
      }
    }
  },
  created () {
    this.$store.dispatch('smartsearch/reset')
  },
  methods: {
    latLngMapper,
    onSubmit (values) {
      const mapped = {
        ...values,
        origin: this.latLngMapper(values.origin),
        destination: this.latLngMapper(values.destination),
        depart: values.date.outbound
      }

      // Store details now so we can direct straight to results later
      Promise.all([
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...flightModel(),
            ...mapped,
            outbound: values.date.outbound,
            inbound: values.date.inbound,
            pickup: {
              location: {
                value: values.origin.value
              },
              date: values.date.outbound
            },
            dropoff: {
              location: {
                value: values.destination.value
              },
              date: addToDate(adjustDate(values.date.outbound, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }), { days: 1 })
            }
          }
        }),
        this.$store.dispatch('carClub/setTraveller', values.user),
        this.$store.dispatch('carClub/setRequester', values.requester),
        this.$store.dispatch('carClub/setSearchLocation', {
          label: values.origin.label,
          value: values.origin.value
        }),
        this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(values.date.outbound)),
        this.$store.dispatch('carClub/setSearchEndDateTime', addToDate(values.date.outbound, { hours: 2 })),
        this.$store.dispatch('rideHailing/setTraveller', values.user),
        this.$store.dispatch('rideHailing/setRequester', values.requester),
        this.$store.dispatch('rideHailing/setLocation', {
          field: 'origin',
          location: {
            label: values.origin.label,
            value: values.origin.value,
            place_id: values.origin.place_id
          }
        }),
        this.$store.dispatch('rideHailing/setLocation', {
          field: 'destination',
          location: {
            label: values.destination.label,
            value: values.destination.value,
            place_id: values.destination.place_id
          }
        }),
        this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: addToDate(values.date.outbound, { minutes: 5 }) }),
        this.$store.dispatch('smartsearch/stash', mapped)
      ]).then(() => {
        this.$store.dispatch('smartsearch/initialQuery')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.code
  background-image url('~assets/cities/London.jpg')
  background-position: 0 60%;
  background-size: 100% 300%;
  text-overflow: clip;
</style>
