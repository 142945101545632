<template>
  <q-page class="page layout-padding bg-white column items-center no-wrap">
    <div class="row q-col-gutter-sm">
      <div v-if="sortedContents && sortedContents.length" class="col-12">
        <div v-for="contentType in sortedContents" :key="`smartsearch-contentcard-${contentType}`">
          <content-card
            v-if="searching || results[contentType]"
            :type="contentType"
            :result="results[contentType]"
          />
        </div>
      </div>
      <m-empty-state
        v-else
        style="margin: auto;"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import contentCard from './content-card'
import { MEmptyState } from 'components/'

export default {
  name: 'SmartSearchResultsDesktop',
  components: {
    contentCard,
    MEmptyState
  },
  computed: {
    ...mapGetters({
      contents: 'smartsearch/contents',
      results: 'smartsearch/results',
      searching: 'smartsearch/ongoingQueries',
      user: 'user'
    }),
    sortedContents () {
      if (!this.contents || !this.contents.length) return null
      const localContents = [...this.contents]
      return localContents.sort((a, b) => {
        if (this.results[a]) return -1
        if (this.results[b]) return 1
        return 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px
</style>
