<template>
  <div class="bg-white column ">
    <results-tabs v-model="currentTab" :tabs="filteredTabs" :current-tab="currentTab" />
    <div class="row q-col-gutter-sm">
      <q-list v-if="filteredResults" class="col-12" separator>
        <result-item
          v-for="(result, type) in filteredResults"
          :key="`smartsearch-contentcard-${type}`"
          :type="type"
          :result="filteredResults[type]"
          :active="currentTab === 'active'"
        />
      </q-list>
      <m-empty-state
        v-else-if="!searching"
        style="margin: auto;"
      />
      <div v-if="searching" class="searching">
        <q-linear-progress indeterminate />
        {{ $t('smartsearch.gathering_results') }}
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import resultsTabs from './tabs.vue'
import resultItem from './resultItem'
import { MEmptyState } from 'components/'

export default {
  name: 'SmartSearchResultsMobile',
  components: {
    resultsTabs,
    resultItem,
    MEmptyState
  },
  data () {
    return {
      initialTab: null,
      tabs: [
        {
          name: 'public',
          label: 'public',
          icon: 'fas fa-car-bus'
        },
        {
          name: 'active',
          label: 'active',
          icon: 'fas fa-heartbeat'
        },
        {
          name: 'on demand',
          label: 'ondemand',
          icon: 'fas fa-taxi'
        },
        {
          name: 'micro',
          label: 'micro',
          icon: 'mdi-scooter'
        },
        {
          name: 'road',
          label: 'road',
          icon: 'fas fa-road'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      contents: 'smartsearch/contents',
      results: 'smartsearch/results',
      searching: 'smartsearch/ongoingQueries',
      user: 'user'
    }),
    currentTab: {
      get () {
        return this.$store.getters['smartsearch/currentTab']
      },
      set (tab) {
        this.$store.dispatch('smartsearch/setTab', tab)
      }
    },
    filteredResults () {
      const results = {}

      Object.keys(this.results).forEach(key => {
        if (this.results[key].content_label.includes(this.currentTab)) {
          results[key] = this.results[key]
        }
      })

      if (!Object.keys(results).length) {
        return null
      }
      return results
    },
    filteredTabs () {
      const availableTabs = []

      Object.keys(this.results).forEach(key => {
        this.results[key].content_label.forEach(label => availableTabs.push(label))
      })

      const tabs = this.tabs.map(tab => {
        return {
          ...tab,
          hasResults: availableTabs.includes(tab.name)
        }
      })

      return tabs
    },
    activeTabs () {
      return this.filteredTabs.filter(tab => tab.hasResults)
    }
  },
  watch: {
    searching () {
      if (!this.currentTab) {
        this.currentTab = this.activeTabs[0].name
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px

.searching
  margin 20px
  height 50px
  width 90vw
  display flex
  flex-direction column
  align-items center
  justify-content space-between
</style>
