<template>
  <q-tabs
    v-if="tabs"
    :value="currentTab"
    active-color="primary"
    class="tabs shadow-1"
    dense
  >
    <q-tab
      v-for="tab in tabs"
      :key="tab.name"
      :icon="tab.icon"
      :name="tab.name"
      class="tab"
      :class="{hasResults: tab.hasResults}"
      @click="onTabChange(tab.name)"
    >
      <div class="smol">
        {{ tab.label }}
      </div>
    </q-tab>
  </q-tabs>
</template>

<script>
export default {
  name: 'ResultsTabs',
  props: {
    tabs: Array,
    currentTab: String
  },
  methods: {
    onTabChange (tab) {
      this.$emit('input', tab)
    }
  }
}
</script>

<style lang="stylus" scoped>
.tabs
  max-width 100vw
.tab
  opacity 0.5
.hasResults
  opacity 1
  animation 0.1875s ease-out 4 dance

.smol
  font-size 0.65em
  font-weight bold

@keyframes dance
  0%
    transform rotate(0deg)
  25%
    transform rotate(-5deg)
  75%
    transform rotate(5deg)
  100%
    transform rotate(0deg)
</style>
