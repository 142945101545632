<template>
  <q-page class="page layout-padding bg-white column no-wrap">
    <SmartSearchResultsDesktop v-if="!$q.platform.is.mobile" />
    <SmartSearchResultsMobile v-else />
  </q-page>
</template>

<script type="text/javascript">
import SmartSearchResultsDesktop from './desktop'
import SmartSearchResultsMobile from './mobile'

export default {
  name: 'SmartSearchResultsWrap',
  components: {
    SmartSearchResultsDesktop,
    SmartSearchResultsMobile
  }
}
</script>
