export const airportMapper = data =>
  data.map(airport => ({
    label: airport.name,
    value: airport.iata,
    icon: 'local_airport',
    color: 'red-5',
    append: airport.iata
  }))

export const trainMapper = data =>
  data.map(station => ({
    label: station.name,
    value: station.atoc,
    icon: 'train',
    color: 'blue-8',
    append: station.atoc
  }))

export const placeMapper = data =>
  data.map(location =>
    ({
      label: location.description,
      value: location.place_id,
      icon: 'place'
    }))

export const latLngMapper = location => {
  const value = location.place_id || `${location.latitude},${location.longitude}`
  return {
    label: location.description || location.label,
    value
  }
}
