<template>
  <q-item class="column text-black" @click.native="onClick">
    <div class="row justify-between">
      <div class="col-4 text-capitalize text-bold ">
        <span>
          {{ result.content_type | contentType }}
        </span>
      </div>
      <div class="col-4 text-right text-bold">
        <span>
          {{ priceText }}
        </span>
      </div>
    </div>
    <div class="row justify-between q-my-sm">
      <div class="col-4">
        <m-travel-icon
          :type="result.content_type"
          :color="getTravelContentHex(result.content_type)"
          size="48px"
        />
      </div>
      <div v-if="isActive" class="active col">
        <span>{{ $t('smartsearch.get_directions', { action: $q.platform.is.mobile ? 'Tap' : 'Click' }) }}</span>
      </div>
    </div>
    <div class="row justify-between">
      <div class="col">
        <span class="q-mr-sm text-primary">
          {{ result.duration.value | duration }}
        </span>
        <span v-if="active">
          ({{ result.distance.miles | distance }} active miles)
        </span>
      </div>
      <div class="col-4 text-right text-bold">
        <span class="q-mr-sm text-primary">
          {{ journeyTime.start | time }} - {{ journeyTime.then | time }}
        </span>
      </div>
    </div>
  </q-item>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
import { Duration } from 'luxon'
import dt from 'utils/date-time'
import travelContents from 'mixins/travelContents'
import { MTravelIcon } from 'components'

export default {
  name: 'SmartSearchResultItem',
  filters: {
    contentType (value) {
      switch (value) {
      case 'bikehire':
        return 'bike hire'
      case 'carclub':
        return 'car club'
      case 'ridehailing':
        return 'taxi'
      case 'bicycle':
        return 'own bike'
      default:
        return value
      }
    },
    distance (value) {
      return Math.round(value * 10) / 10
    },
    duration (value) {
      const duration = Duration.fromObject({ seconds: value })
      return duration.toFormat("hh'h ' mm'm")
    },
    time (val) {
      return dt.toCivilTime(val)
    }
  },
  components: { MTravelIcon },
  mixins: [ travelContents ],
  props: {
    result: {
      type: Object
    },
    active: {
      type: Boolean,
      default: true
    },
    type: String
  },
  computed: {
    ...mapGetters({
      parameters: 'smartsearch/parameters',
      partner: 'partner',
      user: 'user'
    }),
    journeyTime () {
      const { depart, departMin } = this.parameters
      const start = depart ? dt.newDate(depart) || departMin : dt.now(0)
      const then = dt.addToDate(start, { seconds: this.result?.duration?.value || 0 })

      return { start, then }
    },
    bookable () {
      return this.getTravelContentRoute(this.type, 'results')
    },
    isActive () {
      return !this.bookable
    },
    priceText () {
      if (this.type === 'bus' && ['gohi', 'swift'].includes(this.partner.slug)) return 'From £3.90'
      if (['bus', 'tram', 'tube'].includes(this.type)) return '- - -'
      if (this.result?.price?.text) return this.result.price.text
      return this.$t('price_free')
    }
  },
  methods: {
    showContextual () {
      this.$router.push({
        hash: '#/contextual',
        query: { token: this.token, type: this.result.content_type }
      })
    },
    goToResults () {
      const { content_type } = this.result
      if (content_type === 'carclub') {
        if (this.user.has_carclub) {
          this.$router.push({ name: 'ondemand-carclub-results' })
        } else {
          this.$router.push({ hash: '#/user-settings/carclub' })
        }
      } else if (content_type === 'drt') {
        this.$router.push({ name: 'ondemand-drt', query: { smartsearch: true }, params: { origin: this.parameters.origin, destination: this.parameters.destination } })
      } else if (this.bookable) {
        this.$router.push({
          name: this.getTravelContentRoute(this.type, 'results')
        })
      } else {
        const { origin, destination } = this.parameters

        const mapOrigin = /current location/i.test(origin.label) ? origin.value : origin.label
        const mapDestination = /current location/i.test(destination.label) ? destination.value : destination.label
        let travelmode

        switch (content_type) {
        case 'driving': travelmode = 'driving'; break
        case 'tube': travelmode = 'transit'; break
        case 'walking': travelmode = 'walking'; break
        case 'bicycle': travelmode = 'bicycling'; break
        }

        openURL(`https://www.google.com/maps/dir/?api=1&origin=${mapOrigin}&destination=${mapDestination}&travelmode=${travelmode}`)
      }
    },
    onClick () {
      if (!this.result) return
      if (this.$q.platform.is.desktop) this.showContextual()
      else this.goToResults()
    }
  }
}
</script>

<style lang="stylus">
.type-connector
  background-color black
  width 50px
  height 2px
  margin 0 10px
  color: #666
.active
  font-size 0.75em
  text-align right
  display grid
  grid-template-columns auto auto
  grid-template-rows auto auto
  justify-content flex-end
  align-items flex-end
  & span
    grid-area 2 / 2 / 3 / 3
  & img
    grid-row span 2
    align-self center
    border-radius 8px
    width 48px
    margin-right 10px
</style>
