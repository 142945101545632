<template>
  <div class="container">
    <q-card
      square
      class="bg-white"
    >
      <q-card-section :class="`bg-primary text-white text-uppercase`">
        {{ $t('where_are_you_going') }}
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="train-grid">
          <m-location-search
            ref="pickUp"
            :value="parameters.origin"
            :label="$t('going_from')"
            icon="fa fa-location"
            class="origin search-btn"
            :placeholder="$t('choose_origin')"
            no-shadow
            keep-open
            @selected="originSelect"
          />

          <m-location-search
            ref="pickUp"
            :value="parameters.destination"
            :label="$t('going_to')"
            icon="fa fa-map-marker"
            class="destination search-btn"
            :placeholder="$t('choose_location_or_address')"
            no-shadow
            keep-open
            @selected="destinationSelect"
          />

          <q-btn
            flat
            color="primary"
            class="icon swap"
            size="14px"
            icon="fa fa-exchange"
            @click="swap"
          />

          <div class="row q-col-gutter-sm date">
            <div class="col-6">
              <m-date-field
                v-model="parameters.depart"
                :min="minDate"
                :label="$t('date.travel')"
                :outlined="$q.platform.is.mobile"
                :rounded="$q.platform.is.mobile"
              />
            </div>
            <div class="col-6">
              <m-time-field
                v-model="parameters.depart"
                :min="minDate"
                :label="$t('time.from')"
                :outlined="$q.platform.is.mobile"
                :rounded="$q.platform.is.mobile"
              />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <div class="row">
      <div class="col">
        <m-unified-users v-model="people" />
      </div>
    </div>
    <div class="cta">
      <m-cta-button
        :disabled="$v.$invalid"
        :label="$t('discover_travel_options')"
        @ctaClick="submit"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { query } from 'api/places'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { MDateField, MTimeField, MLocationSearch, MUnifiedUsers, MCtaButton } from 'components/'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import store from 'store'
import flightModel from 'pages/ondemand/flights/model'
const { addToDate, adjustDate } = date

export default {
  name: 'SmartSearchForm',
  components: {
    MTimeField,
    MDateField,
    MLocationSearch,
    MUnifiedUsers,
    MCtaButton
  },
  mixins: [ authentication, searchLocation ],
  data () {
    return ({
      dateClicked: false
    })
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng',
      parameters: 'smartsearch/parameters',
      loading: 'smartsearch/loading',
      errors: 'smartsearch/errors'
    }),
    minDate () {
      return addToDate(date.newDate(), { minutes: 5 })
    },
    minReturnDate () {
      return addToDate(this.parameters.depart, { hours: 4 })
    },
    people: {
      get () {
        return {
          requester: this.parameters.requester,
          traveller: this.parameters.user
        }
      },
      set (val) {
        this.$store.dispatch('smartsearch/setRequester', val.requester)
        this.$store.dispatch('smartsearch/setTraveller', val.traveller)
      }
    },
    returning: {
      get () {
        return !!this.parameters.return
      },
      set (value) {
        this.parameters.return = value
          ? date.addToDate(this.parameters.depart, { hours: 4 })
          : null
      }
    }
  },

  watch: {
    loading (value, old) {
      if (value === true) {
        const partner = store.getters.partner
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: partner.slug,
          spinnerColor: partner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (!this.errors) {
          this.$router.push({ name: 'smart-search-results' })
        }
      }
    }
  },
  activated () {
    this.dateClicked = false
  },
  validations: {
    parameters: {
      origin: { required },
      destination: { required },
      user: { required },
      requester: { required }
    }
  },
  methods: {
    originSelect (value) {
      this.parameters.origin = value
    },
    destinationSelect (value) {
      this.parameters.destination = value
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    },
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    },
    onSmartSearchFocus (name) {
      if (this.parameters[name]?.label?.toLowerCase() === 'current location') this.parameters[name] = null
    },
    async submit () {
      const mapped = {
        ...this.parameters
      }

      // Store details now so we can direct straight to results later
      await Promise.all([
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...flightModel(),
            ...mapped,
            outbound: mapped.depart,
            inbound: mapped.return,
            pickup: {
              location: {
                value: mapped.origin.value
              },
              date: mapped.depart
            },
            dropoff: {
              location: {
                value: mapped.destination.value
              },
              date: mapped.return || addToDate(adjustDate(mapped.depart, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }), { days: 1 })
            }
          }
        }),
        this.$store.dispatch('carClub/setTraveller', mapped.user),
        this.$store.dispatch('carClub/setRequester', mapped.requester),
        this.$store.dispatch('carClub/setSearchLocation', {
          label: mapped.origin.label,
          value: mapped.origin.value
        }),
        this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(mapped.depart)),
        this.$store.dispatch('carClub/setSearchEndDateTime', addToDate(mapped.depart, { hours: 2 })),
        this.$store.dispatch('rideHailing/setTraveller', mapped.user),
        this.$store.dispatch('rideHailing/setRequester', mapped.requester),
        this.$store.dispatch('rideHailing/setLocation', {
          field: 'origin',
          location: {
            label: mapped.origin.label,
            value: mapped.origin.value,
            place_id: mapped.origin.place_id
          }
        }),
        this.$store.dispatch('rideHailing/setLocation', {
          field: 'destination',
          location: {
            label: mapped.destination.label,
            value: mapped.destination.value,
            place_id: mapped.destination.place_id
          }
        }),
        this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: addToDate(mapped.depart, { minutes: 5 }) })
      ])

      this.$store.dispatch('smartsearch/initialQuery')
    }
  }
}
</script>
